// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

$mat-bcv: (
  50: #e6f3f2,
  100: #c1e1de,
  200: #98cdc8,
  300: #6eb9b1,
  400: #4faaa1,
  500: #309b90,
  600: #2b9388,
  700: #24897d,
  800: #1e7f73,
  900: #136d61,
  A100: #a4fff1,
  A200: #71ffe9,
  A400: #3effe1,
  A700: #25ffde,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #fff,
    600: #fff,
    700: #fff,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);

$bcv-primary: mat.define-palette(mat.$indigo-palette);
$bcv-accent: mat.define-palette($mat-bcv);

// The warn palette is optional (defaults to red).
$bcv-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$bcv-theme: mat.define-light-theme(
  (
    color: (
      primary: $bcv-primary,
      accent: $bcv-accent,
      warn: $bcv-warn,
    ),
  )
);

@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300;400;500;600;700&display=swap");
$config: mat.define-typography-config(
  $font-family: 'Comfortaa, "Helvetica Neue", sans-serif;',
);
@include mat.core($config);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($bcv-theme);

/* You can add global styles to this file, and also import other style files */

a {
  color: #008a7a;
}
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Comfortaa, "Helvetica Neue", sans-serif;
}

.loader {
  // padding: 40%;
  padding-top: 20%;
  text-align: center;
  width: 100%;
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/* Add application styles & imports to this file! */


.mat-menu-panel {
  &.notifications {
    max-width: 800px !important;
    width: 500px !important;
    .mat-menu-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 80px;
      white-space: normal;
      line-height: 0;
      .message {
        line-height: 20px;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #278177;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #278177aa;
}

@import url('./assets/css/main.css');
@import url('./assets/css/chatBot.css');
