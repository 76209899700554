
html { min-height: 100% }
body {
    color: #888ea8;
    height: 100%;
    font-size: 0.875rem;
    background: #f1f2f3;
    overflow-x: hidden;
    overflow-y: auto;
    letter-spacing: 0.0312rem;
    font-family: 'Nunito', sans-serif;
}
h1, h2, h3, h4, h5, h6 { color: #3b3f5c; }
:focus { outline: none; }
::-moz-selection { /* Code for Firefox */
  color: #1b55e2;
  background: transparent;
}
::selection {
  color: #1b55e2;
  background: transparent;
}
p { margin-top: 0; margin-bottom: 0.625rem; color: #515365 }
hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border-top: 1px solid #f1f2f3;
}
strong { font-weight: 600; }
code { color: #e7515a; }

select.form-custom::-ms-expand {
    display: none;
}
.custom-file-input:focus~.custom-file-label {
    border: 1px solid #ced4da;
    box-shadow: none;
}
.custom-file-input:focus~.custom-file-label::after {
    border: none;
    border-left: 1px solid #ced4da;
}
.lead a.btn.btn-primary.btn-lg {
    margin-top: 15px;
    border-radius: 4px;
}
.jumbotron { background-color: #f1f2f3; }
.mark, mark { background-color: #ffeccb }

.code-section-container {
    margin-top: 20px;
    text-align: left;
}
.toggle-code-snippet {
    border: none;
    background-color: transparent!important;
    padding: 0px!important;
    box-shadow: none!important;
    color: #888ea8!important;
    margin-bottom: -24px;
    border-bottom: 1px dashed #bfc9d4;
    border-radius: 0;
}
.toggle-code-snippet svg {
    color: #1b55e2;
}
.code-section {
    padding: 0;
    height: 0;
}
.code-section-container.show-code .code-section {
    margin-top: 20px;
    height: auto;
}
.code-section pre {
    margin-bottom: 0;
    height: 0;
    padding: 0;
    border-radius: 6px;
}
.code-section-container.show-code .code-section pre {
    height: auto;
    padding: 22px;
}
.code-section code { color: #fff; }

/*blockquote*/
blockquote.blockquote {
    color: #0e1726;
    padding: 20px 20px 20px 14px;
    font-size: 0.875rem;
    background-color: #ffffff;
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    border: 1px solid #e0e6ed;
    border-left: 2px solid #1b55e2;
    -webkit-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
}
blockquote.blockquote > p {
    margin-bottom: 0;
}
blockquote .small:before, blockquote footer:before, blockquote small:before {
    content: '\2014 \00A0';
}
blockquote .small, blockquote footer, blockquote small {
    display: block;
    font-size: 80%;
    line-height: 1.42857143;
    color: #777;
}
blockquote.media-object.m-o-border-right {
    border-right: 4px solid #1b55e2;
    border-left: none;
}
blockquote.media-object .media .usr-img img {
    width: 55px;
}
/* Icon List */

.list-icon {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}
.list-icon li:not(:last-child) {
    margin-bottom: 15px;
}
.list-icon svg {
    width: 18px;
    height: 18px;
    color: #1b55e2;
    margin-right: 2px;
    vertical-align: sub;
}
.list-icon .list-text {
    font-size: 14px;
    font-weight: 600;
    color: #515365;
    letter-spacing: 1px;
}

a {
    color: #515365;
    outline: none;
}
a:hover {
    color: #555555;
    text-decoration: none
}
a:focus {
    outline: none;
    text-decoration: none
}
button:focus, textarea, textarea:focus {
    outline: none;
}
.btn-link:hover {
    text-decoration: none
}
span.blue {
    color: #1b55e2
}
span.green {
    color: #8dbf42
}
span.red {
    color: #e7515a
}

/*      Form Group Label       */

.form-group label, label {
    font-size: 14px;
    color: #595959;
    letter-spacing: 1px;
    line-height: 24px;
}

/*  Disable forms     */

.custom-control-input:disabled~.custom-control-label {
    color: #d3d3d3;
}

/*      Form Control       */

.form-control {
    height: auto;
    border: 1px solid #bfc9d4;
    color: #3b3f5c;
    font-size: 15px;
    letter-spacing: 1px;
    /*height: calc(1.4em + 1.4rem + 2px);*/
    padding: .35rem 1.25rem;
    border-radius: 6px;
}
.form-control:focus {
    box-shadow: 0 0 5px 2px rgba(194, 213, 255, 0.6196078431372549);
    border-color: #1b55e2;
    color: #3b3f5c;
}
.form-control::-webkit-input-placeholder { color: #acb0c3; font-size: 15px; }
.form-control::-ms-input-placeholder { color: #acb0c3; font-size: 15px; }
.form-control::-moz-placeholder { color: #acb0c3; font-size: 15px; }
.form-control:focus::-webkit-input-placeholder { color: #d3d3d3; font-size: 15px; }
.form-control:focus::-ms-input-placeholder { color: #d3d3d3; font-size: 15px; }
.form-control:focus::-moz-placeholder { color: #d3d3d3; font-size: 15px; }
.form-control.form-control-lg {
    font-size: 19px;
    padding: 11px 20px;;
}
.form-control.form-control-sm {
    padding: 7px 16px;
    font-size: 13px;
}


/*      Custom Select       */

.custom-select {
    height: auto;
    border: 1px solid #f1f2f3;
    color: #3b3f5c;
    font-size: 15px;
    padding: 8px 10px;
    letter-spacing: 1px;
    background-color: #f1f2f3;
}
.custom-select.custom-select-lg {
    font-size: 18px;
    padding: 16px 20px;
}
.custom-select.custom-select-sm {
    font-size: 13px;
    padding: 7px 16px;
}
.custom-select:focus {
    box-shadow: none;
    border-color: #1b55e2;
    color: #3b3f5c;
}

/*      Form Control File       */

.form-control-file {
    width: 100%;
    color: #5c1ac3;
}
.form-control-file::-webkit-file-upload-button {
    letter-spacing: 1px;
    padding: 9px 20px;
    text-shadow: none;
    font-size: 12px;
    color: #fff;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    transition: .2s ease-out;
    touch-action: manipulation;
    cursor: pointer;
    background-color: #5c1ac3;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    border-radius: 4px;
    border: transparent;
    outline: none;
}
.form-control-file::-ms-file-upload-button {
    letter-spacing: 1px;
    padding: 9px 20px;
    text-shadow: none;
    font-size: 14px;
    color: #fff;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    transition: .2s ease-out;
    touch-action: manipulation;
    cursor: pointer;
    background-color: #5c1ac3;
    box-shadow: 0px 0px 15px 1px rgba(113, 106, 202, 0.2);
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    border-radius: 4px;
    border: transparent;
    outline: none;
}
.form-control-file.form-control-file-rounded::-webkit-file-upload-button {
    -webkit-border-radius: 1.875rem !important;
    -moz-border-radius: 1.875rem !important;
    -ms-border-radius: 1.875rem !important;
    -o-border-radius: 1.875rem !important;
    border-radius: 1.875rem !important;
}

select.form-control.form-custom {
    display: inline-block;
    width: 100%;
    height: calc(2.25rem + 2px);
    vertical-align: middle;
    /* background: #fff url(../img/arrow-down.png) no-repeat right .75rem center; */
    background-size: 13px 14px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

/*      Form Control Custom File       */

.custom-file { height: auto; }
.custom-file input { height: auto; }
.custom-file-label {
    height: auto;
    border: 1px solid #f1f2f3;
    color: #3b3f5c;
    font-size: 15px;
    padding: 8px 10px;
    letter-spacing: 1px;
    background-color: #f1f2f3;
}
.custom-file-label::after {
    height: auto;
    padding: 8px 12px;
    color: #515365;
}

/*      Input Group      */

.input-group button:hover, .input-group .btn:hover,
.input-group button:focus, .input-group .btn:focus {
    transform: none;
}

/*      Input Group append       */

.input-group .input-group-prepend .input-group-text {
    border: 1px solid #bfc9d4;
    background-color: #f1f2f3;
}
.input-group .input-group-prepend .input-group-text svg { color: #888ea8; }
.input-group:hover .input-group-prepend .input-group-text svg {
    color: #1b55e2;
    fill: rgba(27, 85, 226, 0.23921568627450981);
}

/*      Input Group Append       */
.input-group .input-group-append .input-group-text {
    border: 1px solid #bfc9d4;
    background-color: #f1f2f3;
}
.input-group .input-group-append .input-group-text svg { color: #888ea8; }
.input-group:hover .input-group-append .input-group-text svg {
    color: #1b55e2;
    fill: rgba(27, 85, 226, 0.23921568627450981);
}

/*      Validation Customization      */

.invalid-feedback {
    color: #e7515a;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
}
.valid-feedback {
    color: #8dbf42;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 1px;
}
.valid-tooltip { background-color: #8dbf42; }
.invalid-tooltip { background-color: #e7515a; }
.custom-select.is-valid, .form-control.is-valid, .was-validated .custom-select:valid, .was-validated .form-control:valid {
    border-color: #8dbf42;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%238dbf42' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-check'%3e%3cpolyline points='20 6 9 17 4 12'%3e%3c/polyline%3e%3c/svg%3e");
}
.custom-control-input.is-valid~.custom-control-label, .was-validated .custom-control-input:valid~.custom-control-label {
    color: #8dbf42; }
.form-control.is-invalid, .was-validated .form-control:invalid {
    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23e7515a' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-x'%3e%3cline x1='18' y1='6' x2='6' y2='18'%3e%3c/line%3e%3cline x1='6' y1='6' x2='18' y2='18'%3e%3c/line%3e%3c/svg%3e");
}
.custom-control-input.is-invalid~.custom-control-label, .was-validated .custom-control-input:invalid~.custom-control-label {
    color: #e7515a; }
.dropdown-toggle:after { display: none; }
.dropup .dropdown-toggle::after { display: none; }
.dropright .dropdown-toggle::after { display: none; }
.dropleft .dropdown-toggle::before { display: none; }
.dropdown-toggle svg.feather[class*="feather-chevron-"] {
    width: 15px;
    height: 15px;
    vertical-align: middle;
}
.btn {
    padding: 0.38rem 1.25rem;
    text-shadow: none;
    font-size: 14px;
    color: #3b3f5c;
    font-weight: normal;
    white-space: normal;
    word-wrap: break-word;
    transition: .2s ease-out;
    touch-action: manipulation;
    cursor: pointer;
    background-color: #f1f2f3;
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1);
    will-change: opacity, transform;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
}
.btn svg {
    width: 20px;
    height: 20px;
    vertical-align: bottom;
}
.btn.rounded-circle {
    height: 40px;
    width: 40px;
    padding: 8px 8px;
}
.btn:hover,
.btn:focus {
    color: #3b3f5c;
    background-color: #f1f2f3;
    border-color: #d3d3d3;
    -webkit-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
    -moz-box-shadow: 0 2px 5px 0 rgba(0,0,0,.16),0 2px 10px 0 rgba(0,0,0,.12);
    box-shadow: 0 2px 5px 0 #e0e6ed,0 2px 10px 0 #e0e6ed;
    -webkit-transform: translateY(-3px);
    transform: translateY(-3px);
}
.btn-group .btn:hover,
.btn-group .btn:focus {
    -webkit-transform: none;
    transform: none;
}
.btn.disabled,
.btn.btn[disabled] {
    background-color: #f1f2f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.13)
}
.btn.disabled:hover,
.btn.btn[disabled]:hover {
    cursor: not-allowed
}
.btn .caret {
    border-top-color: #0e1726;
    margin-top: 0;
    margin-left: 3px;
    vertical-align: middle
}
.btn+.caret,
.btn+.dropdown-toggle .caret { margin-left: 0 }
.btn-group>.btn, .btn-group .btn { padding: 8px 14px; }
.btn-group-lg>.btn, .btn-group-lg .btn { font-size: 1.125rem; }
.btn-group-lg>.btn, .btn-lg,
.btn-group>.btn.btn-lg,
.btn-group .btn.btn-lg {
    padding: .625rem 1.5rem;
    font-size: 16px;
}
.btn-group-lg>.btn, .btn-group-lg .btn { font-size: 1.125rem; }
.btn-group-sm>.btn, .btn-sm, .btn-group>.btn.btn-sm, .btn-group .btn.btn-sm { font-size: 0.6875rem; }
.btn-group .dropdown-menu {
    border: 1px solid #e0e6ed;
    box-shadow: rgba(113, 106, 202, 0.2) 0px 0px 15px 1px;
    padding: 10px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
}
.btn-group .dropdown-menu a.dropdown-item {
    border-radius: 5px;
    font-size: 12px;
    font-weight: 700;
    color: #888ea8;
    padding: 11px 8px;
}
.btn-group .dropdown-menu a.dropdown-item:hover {
    background-color: #f1f2f3;
}
.btn-group .dropdown-menu a.dropdown-item svg {
    cursor: pointer;
    color: #888ea8;
    margin-right: 6px;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    fill: rgba(0, 23, 55, 0.08);
}
.btn-group .dropdown-menu a.dropdown-item:hover svg {
    color: #1b55e2;
}
.dropdown:not(.custom-dropdown-icon) .dropdown-menu {
    border: none;
    border: 1px solid #e0e6ed;
    z-index: 899;
    box-shadow: rgba(113, 106, 202, 0.2) 0px 0px 15px 1px;
    padding: 10px;
    border-width: initial;
    border-style: none;
    border-color: initial;
    border-image: initial;
}
.dropdown:not(.custom-dropdown-icon) .dropdown-menu a.dropdown-item {
    font-size: 12px;
    font-weight: normal;
    color: #888ea8;
    padding: 11px 8px;
    border-radius: 6px;
    text-shadow: none;
}
.dropdown:not(.custom-dropdown-icon) .dropdown-menu a.dropdown-item.active,
.dropdown:not(.custom-dropdown-icon) .dropdown-menu a.dropdown-item:active {
    background-color: transparent;
    color: #3b3f5c;
    font-weight: 700;
}
.dropdown:not(.custom-dropdown-icon) .dropdown-menu a.dropdown-item:hover {
    color: #888ea8;
    background-color: #f1f2f3;
}
.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus,
.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show>.btn-success.dropdown-toggle:focus,
.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show>.btn-info.dropdown-toggle:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show>.btn-danger.dropdown-toggle:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show>.btn-warning.dropdown-toggle:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show>.btn-dark.dropdown-toggle:focus,

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-primary.dropdown-toggle:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-success.dropdown-toggle:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-info.dropdown-toggle:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-danger.dropdown-toggle:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-warning.dropdown-toggle:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-secondary.dropdown-toggle:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: none;
}
.btn.focus, .btn:focus,
.btn-success:focus,.btn-info:focus,
.btn-danger:focus,.btn-warning:focus,
.btn-secondary:focus,.btn-dark:focus,
.btn-outline-success:focus,.btn-outline-info:focus,
.btn-outline-danger:focus,.btn-outline-warning:focus,
.btn-outline-secondary:focus,.btn-outline-dark:focus
.btn-light-default:focus,.btn-light-primary:focus,
.btn-light-success:focus,.btn-light-info:focus,
.btn-light-danger:focus,.btn-light-warning:focus,
.btn-light-secondary:focus,.btn-light-dark:focus {
    box-shadow: none;
}


/*      Default Buttons       */

.btn-primary {
    color: #fff !important;
    background-color: #1b55e2!important;
    border-color: #1b55e2;
}
.btn-primary:hover,
.btn-primary:focus {
    color: #fff !important;
    background-color: #1b55e2;
    box-shadow: none;
    border-color: #1b55e2;
}
.btn-primary:active,
.btn-primary.active {
    background-color: #1b55e2;
    border-top: 1px solid #1b55e2
}
.btn-primary.disabled,
.btn-primary.btn[disabled],
.btn-primary:disabled {
    background-color: #1b55e2;
    border-color: #1b55e2;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover,
.btn-primary.focus:active, .btn-primary:active:focus, .btn-primary:active:hover,
.open > .dropdown-toggle.btn-primary.focus, .open > .dropdown-toggle.btn-primary:focus,
.open > .dropdown-toggle.btn-primary:hover {
    color: #fff !important;
    background-color: #2aebcb;
    border-color: #2aebcb;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff !important;
    background-color: #1b55e2;
    border-color: #1b55e2;
}
.btn-primary .caret { border-top-color: #fff }
.btn-group.open .btn-primary.dropdown-toggle { background-color: #c2d5ff }
.btn-secondary {
    color: #fff !important;
    background-color: #5c1ac3;
    border-color: #5c1ac3;
}
.btn-secondary:hover,
.btn-secondary:focus {
    color: #fff !important;
    background-color: #5c1ac3;
    box-shadow: none;
    border-color: #5c1ac3;
}
.btn-secondary:active,
.btn-secondary.active {
    background-color: #5c1ac3;
    border-top: 1px solid #5c1ac3
}
.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
    color: #fff !important;
    background-color: #5c1ac3;
    border-color: #5c1ac3;
}
.btn-secondary.disabled,
.btn-secondary.btn[disabled],
.btn-secondary:disabled {
    background-color: #5c1ac3;
    border-color: #5c1ac3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.btn-secondary .caret { border-top-color: #fff }
.btn-info {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3
}
.btn-info:hover,
.btn-info:focus {
    color: #fff !important;
    background-color: #2196f3;
    box-shadow: none;
    border-color: #2196f3;
}
.btn-info:active,
.btn-info.active {
    background-color: #2196f3;
    border-top: 1px solid #2196f3
}
.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show>.btn-info.dropdown-toggle {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3;
}
.btn-info.disabled,
.btn-info.btn[disabled],
.btn-info:disabled {
    background-color: #2196f3;
    border-color: #2196f3;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover, .btn-info.focus:active, .btn-info:active:focus,
.btn-info:active:hover, .open > .dropdown-toggle.btn-info.focus, .open > .dropdown-toggle.btn-info:focus, .open > .dropdown-toggle.btn-info:hover {
    color: #fff !important;
    background-color: #2196f3;
    border-color: #2196f3
}
.btn-info .caret { border-top-color: #fff }
.btn-group.open .btn-info.dropdown-toggle { background-color: #bae7ff }
.btn-warning {
    color: #fff !important;
    background-color: #e2a03f;
    border-color: #e2a03f;
}
.btn-warning:hover,
.btn-warning:focus {
    color: #fff !important;
    background-color: #e2a03f;
    box-shadow: none;
    border-color: #e2a03f;
}
.btn-warning:active,
.btn-warning.active {
    background-color: #e2a03f;
    border-top: 1px solid #e2a03f
}
.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show>.btn-warning.dropdown-toggle {
    color: #0e1726;
    background-color: #e2a03f;
    border-color: #e2a03f;
}
.btn-warning.disabled,
.btn-warning.btn[disabled],
.btn-warning:disabled {
    background-color: #e2a03f;
    border-color: #e2a03f;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.btn-warning.active.focus, .btn-warning.active:focus, .btn-warning.active:hover, .btn-warning.focus:active, .btn-warning:active:focus,
.btn-warning:active:hover, .open > .dropdown-toggle.btn-warning.focus, .open > .dropdown-toggle.btn-warning:focus, .open > .dropdown-toggle.btn-warning:hover {
    color: #fff !important;
    background-color: #e2a03f;
    border-color: #e2a03f
}
.btn-warning .caret { border-top-color: #fff }
.btn-group.open .btn-warning.dropdown-toggle { background-color: #df8505 }
.btn-danger {
    color: #fff !important;
    background-color: #e7515a;
    border-color: #e7515a;
}
.btn-danger:hover,
.btn-danger:focus {
    color: #fff !important;
    background-color: #e7515a;
    box-shadow: none;
    border-color: #e7515a;
}
.btn-danger:active,
.btn-danger.active {
    background-color: #e7515a;
    border-top: 1px solid #e7515a;
}
.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show>.btn-danger.dropdown-toggle {
    color: #fff !important;
    background-color: #e7515a;
    border-color: #e7515a;
}
.btn-danger.disabled,
.btn-danger.btn[disabled],
.btn-danger:disabled {
    background-color: #e7515a;
    border-color: #e7515a;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.btn-danger.active.focus, .btn-danger.active:focus, .btn-danger.active:hover, .btn-danger.focus:active, .btn-danger:active:focus,
.btn-danger:active:hover, .open > .dropdown-toggle.btn-danger.focus, .open > .dropdown-toggle.btn-danger:focus, .open > .dropdown-toggle.btn-danger:hover {
    color: #fff !important;
    background-color: #c00;
    border-color: #c00
}
.btn-danger .caret { border-top-color: #fff }
.btn-group.open .btn-danger.dropdown-toggle { background-color: #a9302a }
.btn-dark {
    color: #fff !important;
    background-color: #3b3f5c;
    border-color: #3b3f5c;
}
.btn-dark:hover,
.btn-dark:focus {
    color: #fff !important;
    background-color: #3b3f5c;
    box-shadow: none;
    border-color: #3b3f5c;
}
.btn-dark:active,
.btn-dark.active {
    background-color: #3b3f5c;
    border-top: 1px solid #3b3f5c
}
.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show>.btn-dark.dropdown-toggle {
    color: #fff !important;
    background-color: #3b3f5c;
    border-color: #3b3f5c;
}
.btn-dark.disabled,
.btn-dark.btn[disabled],
.btn-dark:disabled {
    background-color: #3b3f5c;
    border-color: #3b3f5c;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.btn-dark .caret { border-top-color: #fff }
.btn-group.open .btn-dark.dropdown-toggle { background-color: #484848 }
.btn-success {
    color: #fff !important;
    background-color: #8dbf42;
    border-color: #8dbf42
}
.btn-success:hover,
.btn-success:focus {
    color: #fff !important;
    background-color: #8dbf42;
    box-shadow: none;
    border-color: #8dbf42;
}
.btn-success:active,
.btn-success.active {
    background-color: #8dbf42;
    border-top: 1px solid #8dbf42
}
.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show>.btn-success.dropdown-toggle {
    color: #fff !important;
    background-color: #8dbf42;
    border-color: #8dbf42;
}
.btn-success.disabled,
.btn-success.btn[disabled],
.btn-success:disabled {
    background-color: #8dbf42;
    border-color: #8dbf42;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}
.btn-success.active.focus, .btn-success.active:focus, .btn-success.active:hover, .btn-success.focus:active, .btn-success:active:focus,
.btn-success:active:hover, .open > .dropdown-toggle.btn-success.focus, .open > .dropdown-toggle.btn-success:focus, .open > .dropdown-toggle.btn-success:hover {
    color: #fff !important;
    background-color: #17c678;
    border-color: #17c678
}
.btn-success .caret { border-top-color: #fff }



/*-----/Button Light Colors------*/

.btn.box-shadow-none { border: none; }
.btn.box-shadow-none:hover, .btn.box-shadow-none:focus {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}
.box-shadow-none {
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
}
.btn.box-shadow-none:not(:disabled):not(.disabled).active,
.btn.box-shadow-none:not(:disabled):not(.disabled):active,
.show>.btn.box-shadow-none.dropdown-toggle {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    background-color: transparent;
}
.btn-group.open .btn-success.dropdown-toggle { background-color: #499249 }
.btn-dismiss {
    color: #0e1726;
    background-color: #fff !important;
    border-color: #fff;
    padding: 3px 7px;
}
.btn-dismiss:hover,
.btn-dismiss:focus {
    color: #0e1726;
    background-color: #fff;
}

.btn-dismiss:active,
.btn-dismiss.active {
    background-color: #fff;
    border-top: 1px solid #fff
}
.btn-group>.btn i {
    margin-right: 3px
}
.btn-group > .btn:first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    -webkit-box-shadow: 0 0px 0px 0 rgba(0,0,0,.16),0 0px 0px 0 rgba(0,0,0,.12);
    -moz-box-shadow: 0 0px 0px 0 rgba(0,0,0,.16),0 0px 0px 0 rgba(0,0,0,.12);
    box-shadow: 0 0px 0px 0 rgba(0,0,0,.16),0 0px 0px 0 rgba(0,0,0,.12)
}
.btn-group>.btn+.dropdown-toggle {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none
}

/*
    Btn group dropdown-toggle
*/
.btn-group>.btn+.dropdown-toggle.btn-primary {
    border-left: 1px solid rgb(68, 104, 253);
}
.btn-group>.btn+.dropdown-toggle.btn-success {
    border-left: 1px solid rgb(163, 198, 111);
}
.btn-group>.btn+.dropdown-toggle.btn-info {
    border-left: 1px solid rgb(73, 172, 251);
}
.btn-group>.btn+.dropdown-toggle.btn-warning {
    border-left: 1px solid rgb(245, 180, 85);
}
.btn-group>.btn+.dropdown-toggle.btn-danger {
    border-left: 1px solid rgb(241, 132, 139);
}
.btn-group>.btn+.dropdown-toggle.btn-dark {
    border-left: 1px solid rgb(112, 118, 122);
}
.btn-group>.btn+.dropdown-toggle.btn-secondary {
    border-left: 1px solid rgb(131, 83, 220);
}
.btn-group.dropleft .dropdown-toggle-split {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.btn-group.dropleft .btn-primary:not(.dropdown-toggle-split) {
    border-left: 1px solid rgb(68, 104, 253);
}
.btn-group.dropleft .btn-success:not(.dropdown-toggle-split) {
    border-left: 1px solid rgb(163, 198, 111);
}
.btn-group.dropleft .btn-info:not(.dropdown-toggle-split) {
    border-left: 1px solid rgb(73, 172, 251);
}
.btn-group.dropleft .btn-warning:not(.dropdown-toggle-split) {
    border-left: 1px solid rgb(245, 180, 85);
}
.btn-group.dropleft .btn-danger:not(.dropdown-toggle-split) {
    border-left: 1px solid rgb(241, 132, 139);
}
.btn-group.dropleft .btn-dark:not(.dropdown-toggle-split) {
    border-left: 1px solid rgb(112, 118, 122);
}
.btn-group.dropleft .btn-secondary:not(.dropdown-toggle-split) {
    border-left: 1px solid rgb(131, 83, 220);
}
.btn .badge.badge-align-right {
    position: absolute;
    top: -1px;
    right: 8px;
}
.dropup .btn .caret {
    border-bottom-color: #0e1726
}
.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
    background-color: #1b55e2;
    color: #fff!important;
}
.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show>.btn-outline-success.dropdown-toggle {
    background-color: #8dbf42;
    color: #fff!important;
}
.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show>.btn-outline-info.dropdown-toggle {
    background-color: #2196f3;
    color: #fff!important;
}
.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show>.btn-outline-danger.dropdown-toggle {
    background-color: #e7515a;
    color: #fff!important;
}
.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show>.btn-outline-warning.dropdown-toggle {
    background-color: #e2a03f;
    color: #fff!important;
}
.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show>.btn-outline-secondary.dropdown-toggle {
    background-color: #5c1ac3;
    color: #fff!important;
}
.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show>.btn-outline-dark.dropdown-toggle {
    background-color: #3b3f5c;
    color: #fff!important;
}
.show>.btn-outline-primary.dropdown-toggle:after, .show>.btn-outline-success.dropdown-toggle:after, .show>.btn-outline-info.dropdown-toggle:after, .show>.btn-outline-danger.dropdown-toggle:after, .show>.btn-outline-warning.dropdown-toggle:after, .show>.btn-outline-secondary.dropdown-toggle:after, .show>.btn-outline-dark.dropdown-toggle:after {
    color: #fff!important;
}
.show>.btn-outline-primary.dropdown-toggle:before, .show>.btn-outline-success.dropdown-toggle:before, .show>.btn-outline-info.dropdown-toggle:before, .show>.btn-outline-danger.dropdown-toggle:before, .show>.btn-outline-warning.dropdown-toggle:before, .show>.btn-outline-secondary.dropdown-toggle:before, .show>.btn-outline-dark.dropdown-toggle:before {
    color: #fff!important;
}
.btn-outline-primary {
    border: 1px solid #1b55e2 !important;
    color: #1b55e2 !important;
    background-color: transparent;
    box-shadow: none;
}
.btn-outline-info {
    border: 1px solid #2196f3 !important;
    color: #2196f3 !important;
    background-color: transparent;
    box-shadow: none;
}
.btn-outline-warning {
    border: 1px solid #e2a03f !important;
    color: #e2a03f !important;
    background-color: transparent;
    box-shadow: none;
}
.btn-outline-success {
    border: 1px solid #8dbf42 !important;
    color: #8dbf42 !important;
    background-color: transparent;
    box-shadow: none;
}
.btn-outline-danger {
    border: 1px solid #e7515a !important;
    color: #e7515a !important;
    background-color: transparent;
    box-shadow: none;
}
.btn-outline-secondary {
    border: 1px solid #5c1ac3 !important;
    color: #5c1ac3 !important;
    background-color: transparent;
    box-shadow: none;
}
.btn-outline-dark {
    border: 1px solid #3b3f5c !important;
    color: #3b3f5c !important;
    background-color: transparent;
    box-shadow: none;
}
.btn-outline-primary:hover,
.btn-outline-info:hover,
.btn-outline-warning:hover,
.btn-outline-success:hover,
.btn-outline-danger:hover,
.btn-outline-secondary:hover,
.btn-outline-dark:hover {
    box-shadow: 0px 5px 20px 0 rgba(0, 0, 0, 0.1)
}
.btn-outline-primary:hover {
    color: #fff !important;
    background-color: #FFFFFF;
}
.btn-outline-info:hover {
    color: #fff !important;
    background-color: #FFFFFF
}
.btn-outline-warning:hover {
    color: #fff !important;
    background-color: #FFFFFF;
}
.btn-outline-success:hover {
    color: #fff !important;
    background-color: #FFFFFF;
}
.btn-outline-danger:hover {
    color: #fff !important;
    background-color: #FFFFFF;
}
.btn-outline-secondary:hover {
    color: #fff !important;
    background-color: #FFFFFF;
}
.btn-outline-dark:hover {
    color: #fff !important;
    background-color: #FFFFFF
}

/*      Dropdown Toggle       */

.btn-rounded {
    -webkit-border-radius: 1.875rem !important;
    -moz-border-radius: 1.875rem !important;
    -ms-border-radius: 1.875rem !important;
    -o-border-radius: 1.875rem !important;
    border-radius: 1.875rem !important;
}

.hide{
    display: none !important;
}
.cursor-pointer {
    cursor: pointer;
}
.select2-container .select2-dropdown .select2-search .select2-search__field{
    border: 1px solid #c0c0c0;
    font-size: 13px;
    padding: 4px 10px;
}
.select2-container .select2-dropdown .select2-results .select2-results__options .select2-results__option{
    padding: 4px 10px;
    font-size: 13px;
    font-weight: normal;
}
.select2-dropdown{
    z-index: 999999999 !important;
}
